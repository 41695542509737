














































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class OrganisationAddEdit extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public organisation: any = {};
  public subscriptionPlans: any = [];
  public selectedSubscriptionPlan: any = null;
  public subscriptionPlansOptions: any = {};

  public async created() {
    this.refresh();
  }

  @Watch('$route')
  private refresh() {
    this.loading = true;
    if (this.id !== null) {
      this.$store.dispatch('loadOrganisationById', {
        organisationId: this.id,
      }).then((response: any) => {
        this.organisation = response;
        this.loading = false;
      }).catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
    } else {
      this.loading = false;
    }

    this.$store.dispatch('loadSubscriptionPlans').then((response: any) => {
      this.subscriptionPlans = response.subscription_plans;
    });

    this.$store.dispatch(
      'loadSubscriptionPlansOptions',
    ).then((response: any) => {
      this.subscriptionPlansOptions = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  @Watch('organisation', {deep: true})
  @Watch('subscriptionPlans')
  private updateSelectedSubscriptionPlan() {
    if (this.subscriptionPlans.length === 0) {
      return;
    }
    if (this.organisation.subscription_plan_id === null) {
      if (this.selectedSubscriptionPlan !== undefined) {
        this.selectedSubscriptionPlan = undefined;
        Vue.set(this.organisation, 'subscription_options', {});
      }
    } else {
      this.selectedSubscriptionPlan = this.subscriptionPlans.find(
        (entry: any) => entry.id === this.organisation.subscription_plan_id);
    }
    if (this.organisation.subscription_options === undefined) {
      const defaultOptions: any = {};
      for (const optionKey of Object.keys(this.subscriptionPlansOptions)) {
        const defaultValue = this.subscriptionPlansOptions[optionKey].default_value;
        if (defaultValue !== undefined) {
          defaultOptions[optionKey] = defaultValue;
        }
      }
      Vue.set(this.organisation, 'subscription_options', defaultOptions);
    }
  }

  private setSubscriptionOption(key: string, value: any) {
    Vue.set(this.organisation.subscription_options, key, value);
  }

  private onSubmit() {
    this.loading = true;

    const data: any = {
      payload: {
        enabled: this.organisation.enabled,
        name: this.organisation.name,
        street: this.organisation.street,
        street_number: this.organisation.street_number,
        vat_number: this.organisation.vat_number || '',
        city: this.organisation.city,
        zipCode: this.organisation.zipCode,
        subscription_plan_id: this.organisation.subscription_plan_id,
        subscription_options: this.organisation.subscription_options,
        can_access_indoor: this.organisation.can_access_indoor,
        can_access_outdoor: this.organisation.can_access_outdoor,
        can_access_mobility: this.organisation.can_access_mobility,
        housing_mode: this.organisation.housing_mode,
      },
    };

    let name = 'editOrganisation';
    let method = 'editée';
    if (this.id === null) {
      name = 'addOrganisation';
      method = 'ajoutée';
    } else {
      data.organisationId = this.id;
    }

    this.$store.dispatch(name, data)
    .then((response: any) => {
      this.$q.notify({
          message: `${response.name} a été ${method}`,
          color: 'teal',
        });
      this.$router.push(`/admin/organisation/${response.id}`);
      this.organisation = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }
}
